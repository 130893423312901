import { Link } from "gatsby"
import React from "react"
import getStripe from "../../services/stripejs"
import TableColumn from "./tableColumn.js"

const PLANDETAILS = [
  {
    name: "Free",
    highlight: false,
    price: "Free",
    billingInterval: " (forever)",
    overview: "The basics if you are just starting out",
    bullets: [
      "Hydra access",
      "Metered Hydra Execution",
      "Telegram Community Chat Support",
    ],
    buttonText: "Get Started",
    stripeProduct: "",
  },
  {
    name: "Advanced",
    highlight: true,
    price: "$19",
    billingInterval: "/month",
    overview: "Advanced testing capabilities for experienced developers",
    bullets: [
      "Faster Hydra test execution",
      "LiquidApps VRAM support in Hydra",
      "Unmetered Hydra execution",
      "Telegram Community Chat Support",
    ],
    buttonText: "Get Advanced",
    stripeProduct: "",
  },
  {
    name: "Pro",
    price: "$129",
    highlight: false,
    billingInterval: "/month",
    overview: "Automated bug and vulnerability discovery",
    bullets: [
      "Everything included in Advanced",
      "Inspect static vulnerability analyser",
      "15 mins per analysis job",
      "Actionable security reports",
      "Priority support",
    ],
    buttonText: "Get Pro",
    stripeProduct: "",
  },
]

const PricingTable = ({ products }) => {
  const AddProductsToPlan = () => {
    console.log("Adding products")

    PLANDETAILS.map((key, index) => {
      key.stripeProduct = products[Object.keys(products)[index]]
      //console.log("Stripe product: ", key.stripeProduct.prices)
    })
  }

  AddProductsToPlan()

  return (
    <>
      {/* Page Container */}
      <div className="min-h-screen bg-gray-300 text-gray-800">
        {/* Pricing Tables */}
        <div className="container p-4 lg:p-8 mx-auto">
          <div className="text-center mt-4 lg:mt-8">
            <div className="text-sm text-cardinal uppercase font-semibold tracking-wider">
              Pricing Plans
            </div>
            <h2 className="text-3xl md:text-4xl font-bold mb-1">
              Choose the best plan for you
            </h2>
            <h3 className="text-lg md:text-xl text-gray-700 lg:w-1/2 lg:mx-auto mb-10">
              Check your profile to see <span className="text-amaranth hover:underline"><Link to="/app/profile">your current plan.</Link></span><br></br>
              <a
                href="mailto:hello@klevoya.com?subject=Cancel%20subscription"
                className="text-amaranth hover:underline"
              >
                Cancel
              </a>{" "}
              or{" "}
              <a
                href="mailto:hello@klevoya.com?subject=Downgrade%20subscription"
                className="text-amaranth hover:underline"
              >
                downgrade
              </a>{" "}
              at any time.
              <br></br>
              For annual plans or payments in crypto <a href="mailto:hello@klevoya.com?subject=Payment%20plan"
                className="text-amaranth hover:underline">contact us</a>.
            </h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8">
            {Object.keys(PLANDETAILS).map(key => (
              <TableColumn key={key} planDetail={PLANDETAILS[key]} />
            ))}
            {/* Advanced Plan 
            <div className="rounded-lg shadow-sm bg-white flex flex-col border-4 border-amaranth">
              <div className="p-5 lg:p-6 text-center flex-grow">
                <span className="inline-flex space-x-1 items-center text-sm uppercase tracking-wider font-semibold px-3 py-1 bg-amaranth bg-opacity-80 text-paris-daisy rounded-full mb-4">
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                  </svg>
                  <span>Advanced</span>
                </span>
                <div>
                  <span className="text-3xl lg:text-5xl font-extrabold">
                    $20
                  </span>
                  <span className="text-lg text-gray-600 font-semibold">
                    /mon
                  </span>
                </div>
                <p className="text-gray-600">
                  Advanced testing capabilities for experienced developers
                </p>
              </div>
              <div className="p-5 lg:p-6 bg-gray-100 space-y-5 lg:space-y-6 text-gray-700 rounded-b-lg">
                <ul className="space-y-4 text-sm lg:text-base">
                  <li className="flex items-center space-x-2">
                    <svg
                      className="text-green-500 w-5 h-5 inline-block"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M5 13l4 4L19 7" />
                    </svg>
                    <span>
                      <strong>Hydra</strong> access
                    </span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <svg
                      className="text-green-500 w-5 h-5 inline-block"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M5 13l4 4L19 7" />
                    </svg>
                    <span>
                      <strong>LiquidApps</strong> VRAM support in Hydra
                    </span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <svg
                      className="text-green-500 w-5 h-5 inline-block"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M5 13l4 4L19 7" />
                    </svg>
                    <span>
                      <strong>Unmetered</strong> Hydra Execution
                    </span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <svg
                      className="text-green-500 w-5 h-5 inline-block"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M5 13l4 4L19 7" />
                    </svg>
                    <span>
                      <strong>Telegram</strong> Community Chat Support
                    </span>
                  </li>
                </ul>
                <a
                  className="px-4 py-3 text-white text-center uppercase font-semibold rounded bg-amaranth hover:bg-cardinal block w-full"
                  href="javascript:void(0)"
                >
                  Get Advanced
                </a>
              </div>
            </div> */}
            {/* END Advanced Plan */}
          </div>
        </div>
        {/* END Pricing Tables */}
        {/* Footer */}
      </div>
      {/* END Page Container */}
    </>
  )
}

export default PricingTable
