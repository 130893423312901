import React, { useState } from "react"
import { getProfile } from "../../services/auth"
import getStripe from "../../services/stripejs"

const FormatBullets = bullets => {
  const listItems = bullets.map((bullet, index) => (
    <li className="flex items-center space-x-2" key={index}>
      {/* Tick */}
      <svg
        className="text-green-500 w-5 h-5 inline-block"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M5 13l4 4L19 7" />
      </svg>
      <span>{bullet}</span>
    </li>
  ))
  return <ul className="space-y-4 text-sm lg:text-base">{listItems}</ul>
}

const ShowThmbsUp = isHighlight => {
  if (isHighlight) {
    {
      /*Thumbs up icon */
    }
    return (
      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
        <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
      </svg>
    )
  }
}

{
  /* Tailwind styles: "px-4 py-3 text-white text-center uppercase font-semibold rounded bg-gray-700 hover:bg-gray-900 block w-full"  */
}
const purchaseButtonStyles = {
  display: "block",
  textAlign: "center",
  textTransform: "uppercase",
  textDecoration: "inherit",
  fontWeight: "600",
  color: "rgb(255, 255, 255)",
  padding: "0.75rem",
  boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
  backgroundColor: "rgb(55, 65, 81)",
  /* TODO: Implement hover to change bg colour */
  borderRadius: "0.25rem",
  width: "100%",
}

const buttonDisabledStyles = {
  opacity: "0.5",
  cursor: "not-allowed",
}

const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2)
  let numberFormat = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  })
  return numberFormat.format(price)
}

const TableColumn = ({ planDetail }) => {
  const [loading, setLoading] = useState(false)
  const [priceId, setPriceId] = useState(planDetail.stripeProduct.prices[0].id)

  const handleClick = async () => {
    const price = priceId
    //event.preventDefault()
    setLoading(true)

    const stripe = await getStripe()
    const user = getProfile()
    let error = ``
    // we need this auth0 user id to link it to the stripe customer in backend
    if (!user.sub) error = `Could not get Auth0 user ID`
    else {
      ;({ error } = await stripe.redirectToCheckout({
        mode: "subscription",
        lineItems: [{ price, quantity: 1 }],
        successUrl: `${window.location.origin}/subscriptionSuccess`,
        cancelUrl: `${window.location.origin}/app/subscription`,
        clientReferenceId: user.sub,
        customerEmail: user.email,
      }))
    }

    if (error) {
      console.warn("Error:", error)
      setLoading(false)
    }
  }

  const PurchaseButton = buttonText => {
    return (
      <button
        disabled={loading}
        style={
          loading
            ? { ...purchaseButtonStyles, ...buttonDisabledStyles }
            : purchaseButtonStyles
        }
        onClick={() => handleClick()}
      >
        {" "}
        {buttonText}
      </button>
    )
  }
  const GetHydraButton = buttonText => {
    return (
      <a
        className="px-4 py-3 text-white text-center uppercase font-semibold rounded bg-amaranth hover:bg-cardinal block w-full"
        href="https://docs.klevoya.com/hydra/about/getting-started"
      >
        {buttonText}
      </a>
    )
  }
  return (
    <div>
      <div className="rounded-lg shadow-sm bg-white flex flex-col border-4 border-white">
        <div className="p-5 lg:p-6 text-center flex-grow">
          <span className="font-sans inline-flex space-x-1 items-center text-sm uppercase tracking-wider font-semibold px-3 py-1 bg-amaranth bg-opacity-80 text-paris-daisy rounded-full mb-4">
            {ShowThmbsUp(planDetail.highlight)}
            {planDetail.name}
          </span>
          <div>
            <span className="text-3xl lg:text-5xl font-extrabold">
              {planDetail.price}
            </span>
            <span className="text-lg text-gray-600 font-semibold">
              {planDetail.billingInterval}
            </span>
          </div>
          <p className="text-gray-600">{planDetail.overview}</p>
        </div>
        <div className="p-5 lg:p-6 bg-gray-100 space-y-5 lg:space-y-6 text-gray-700 rounded-b-lg">
          {console.log(
            "In column got stripe product: ",
            planDetail.stripeProduct
          )}
          {FormatBullets(planDetail.bullets)}
          {planDetail.name === "Free"
            ? GetHydraButton(planDetail.buttonText)
            : PurchaseButton(planDetail.buttonText)}
        </div>
      </div>
    </div>
  )
}

export default TableColumn
