import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 bg-gray-100 shadow">
        <div className="px-4 py-6 sm:px-0">
          <div className="border-2 border-gray-200 rounded-lg h-96 bg-white">
            <div className="container mx-auto m-24">
              <div className="flex justify-center">
                <article className="prose prose-sm color-mine-shaft">
                  <h1>PAGE NOT FOUND</h1>
                  <p>
                    You just hit a route that doesn&#39;t exist... the sadness.
                    <br></br>
                    Try navigating to one of the links above instead.
                  </p>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
)

export default NotFoundPage