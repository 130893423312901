import React from "react"
import { Redirect } from "gatsby-link"
import { isAuthenticated } from "../services/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isAuthenticated() && location.pathname !== `/app`) {
    return <Redirect to="/app" />
  }

  return <Component {...rest} />
}

export default PrivateRoute