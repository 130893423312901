import React from "react"
import { Router } from "@reach/router"
import { login, isAuthenticated, getProfile } from "../services/auth"
import { Link } from "gatsby"
import PrivateRoute from "../components/privateRoute"
import Profile from "../components/profile"
import Layout from "../components/layout"
import { withPrefix } from "gatsby"
import Subscription from "../components/Subscribtion/subscription"
import SubscriptionSuccess from "./"
import styled from "@emotion/styled"
import NotFoundPage from "./NotFoundPage"

{
  /*import PrivateRoute from "../components/privateRoute"
import Profile from "../components/profile"
import Login from "../components/login"

const App = () => (
  <Layout>
    <Router>
    <PrivateRoute path="/app/profile" component={Profile} />
    <Login path="/app/login" />
    </Router>
  </Layout>
)

export default App*/
}

{
  /* Tailwind classes: "flex-1 items-center justify-center px-4 py-2 my-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amaranth hover:bg-cardinal"
 */
}

const ButtonLink = styled(Link)`
  color: #FFFFFF;
  --tw-text-opacity: 1;
  flex-shrink: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-radius: 0.375rem;
  border-color: transparent;
  background-color: rgba(229, 43, 80, var(--tw-bg-opacity));
  &:hover {
    background: #C5183A;
    --tw-bg-opacity: 1;
  }
`
const Home = ({ user }) => {
  return (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 bg-gray-100 shadow">
        <div className="px-4 py-6 sm:px-0">
          <div className="border-2 border-gray-100 rounded-lg h-100 bg-gray-100">
            <div className="container mx-auto">
              <div className="flex justify-center">
                <div className="relative overflow-hidden mb-8">
                  <div className="my-12 text-center">
                    <h1 className="text-4xl font-black text-cardinal subpixel-antialiased text-opacity-90">
                      The modern way
                    </h1>
                    <h2 className="text-3xl text-gray-800 subpixel-antialiased">
                      {" "}
                      to test your smart contracts
                    </h2>
                  </div>

                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-3">
                    <div className="shadow-lg h-60 w-60 bg-white rounded-md flex text-center items-center justify-center">
                      <div>
                        <div className="prose">
                          <h3>Start</h3>
                          <p>
                            <div className="text-sm">
                              Writing better tests.
                              <br></br>
                              Fire up your terminal and tap the button below
                            </div>
                            <br></br>
                          </p>
                        </div>
                        <a
                          href="https://docs.klevoya.com"
                          className="flex-1 items-center justify-center px-4 py-2 my-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amaranth hover:bg-cardinal"
                        >
                          See Docs
                        </a>
                      </div>
                    </div>
                    <div className="shadow-lg h-60 w-60 bg-white rounded-md flex text-center items-center justify-center">
                      <div>
                        <div className="prose">
                          <h3>Upgrade</h3>
                          <p>
                            <div className="text-sm">
                              For advanced features
                              <br></br>
                              like unmetered tests, VRAM and Inspect tap below
                              to upgrade.
                            </div>
                            <br></br>
                          </p>
                        </div>
                        <ButtonLink to="/app/subscription">Go Pro</ButtonLink>
                      </div>
                    </div>
                    <div className="shadow-lg h-60 w-60 bg-white rounded-md flex text-center items-center justify-center">
                      <div>
                        <div className="prose">
                          <h3>Spread</h3>
                          <p>
                            <div className="text-sm">
                              The word.
                              <br></br>
                              IFF you like what we do, coz
                              <br></br>
                              we'd rather buy servers, not ads!
                            </div>
                            <br></br>
                          </p>
                        </div>

                        <a
                          href="https://ctt.ac/BzUT5"
                          className="flex-1 items-center justify-center px-4 py-2 my-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amaranth hover:bg-cardinal"
                        >
                          Retweet
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

const App = () => {
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  const user = getProfile()

  return (
    <Layout>
      {/*
      <Router basepath={withPrefix("/app")}>
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/subscription" component={Subscription} />
        <Home path="/" user={user} />
      </Router>
      */}

      <Router>
        <PrivateRoute path="/app/profile" component={Profile} />
        <PrivateRoute path="/app/subscription" component={Subscription} />
        <Home path="/app" user={user} />
        <NotFoundPage default />
      </Router>
    </Layout>
  )
}

export default App
