import React from "react"
import { getProfile } from "../services/auth"

const Profile = () => {
  const user = getProfile()
  let userTier = "Free"

  try {
    userTier = user['https://klevoya.com/user_metadata'].subscription.tier
  } catch (err) {
    console.warn("Unable to get user's profile tier.")
  }

  return (
    <>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <article className="prose prose-sm color-mine-shaft">
            <h3>Your profile</h3>
            <ul>
              <li>Name: {user.name}</li>
              <li>E-mail: {user.email}</li>
              <li>Current plan: <span className="capitalize">{userTier}</span></li>
            </ul>
          </article>
        </div>
      </header>
    </>
  )
}

export default Profile
