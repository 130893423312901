import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PricingTable from "../Products/pricingTable"

const Subscription = () => {
  return (
    <StaticQuery
      query={graphql`
        query ProductPrices {
          prices: allStripePrice(
            filter: { active: { eq: true } }
            sort: { fields: [unit_amount] }
          ) {
            edges {
              node {
                id
                active
                currency
                unit_amount
                product {
                  id
                  name
                }
              }
            }
          }
        }
      `}
      render={({ prices }) => {
        // Group prices by product
        const products = {}
        for (const { node: price } of prices.edges) {
          const product = price.product
          if (!products[product.id]) {
            products[product.id] = product
            products[product.id].prices = []
          }
          products[product.id].prices.push(price)
        }
        /* console.log("Found " + Object.keys(products).length + " products.") */
        console.assert(
          Object.keys(products).length === 3,
          "Unexpected number of products received from Stripe."
        )

        /* console.log(products[Object.keys(products)[0]])
        
        const product2 = products[Object.keys(products)[1]]
        console.log(product2) */

        return <PricingTable products={products} />
      }}
    />
  )
}

export default Subscription
